.App-Main {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: transparent !important;
  height: 100%;
  width: 100%; }

.App-Headline {
  margin-top: 30vh;
  margin-bottom: 10vh;
  color: rgba(253, 106, 2, 0.9);
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  vertical-align: center;
  font-weight: 600 !important;
  font-size: 150px !important; }

.App-Headline-Mobile {
  margin-top: 30vh;
  margin-bottom: 10vh;
  color: rgba(253, 106, 2, 0.9);
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  vertical-align: center;
  font-weight: 600 !important;
  font-size: 50px !important; }

.App-Sub-Headline {
  color: rgba(253, 106, 2, 0.9);
  margin-bottom: 15vh !important; }

.App-Sub-Headline-Mobile {
  color: rgba(253, 106, 2, 0.9);
  margin-bottom: 15vh !important;
  font-size: 25px !important; }

.Disclaimer-Headline {
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: rgba(253, 106, 2, 0.9);
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  vertical-align: center;
  font-weight: 600 !important;
  font-size: 150px !important; }

.Disclaimer-Headline-Mobile {
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: rgba(253, 106, 2, 0.9);
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  vertical-align: center;
  font-weight: 600 !important;
  font-size: 50px !important; }

.Disclaimer-Sub-Headline {
  color: rgba(253, 106, 2, 0.9);
  margin-top: 15vh !important;
  margin-bottom: 20vh !important;
  font-size: 25px !important; }

.Disclaimer-Sub-Headline-Mobile {
  color: rgba(253, 106, 2, 0.9);
  margin-top: 5vh !important;
  margin-bottom: 5vh !important;
  margin-left: 3vw;
  margin-right: 3vw;
  font-size: 18px !important; }

.Completion-Headline {
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: rgba(253, 106, 2, 0.9);
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  vertical-align: center;
  font-weight: 600 !important;
  font-size: 150px !important; }

.Completion-Sub-Headline {
  color: rgba(253, 106, 2, 0.9);
  margin-top: 5vh !important;
  margin-bottom: 5vh !important;
  margin-left: 3vw;
  margin-right: 3vw;
  font-size: 25px !important; }

.Completion-Headline-Mobile {
  margin-top: 10vh;
  margin-bottom: 10vh;
  color: rgba(253, 106, 2, 0.9);
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  vertical-align: center;
  font-weight: 600 !important;
  font-size: 50px !important; }

.Completion-Sub-Headline-Mobile {
  color: rgba(253, 106, 2, 0.9);
  margin-top: 5vh !important;
  margin-bottom: 5vh !important;
  margin-left: 3vw;
  margin-right: 3vw;
  font-size: 18px !important; }

.form-require {
  position: absolute;
  color: rgba(253, 106, 2, 0.9);
  left: 2vw; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > div > div.button-container.animated.bounceInUp > button {
  color: white;
  background-color: rgba(253, 106, 2, 0.9);
  border: none !important;
  margin-bottom: 4vh; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > div > div.button-container.animated.bounceInUp > button:hover {
  box-shadow: 0px 0px 0px 2px rgba(253, 106, 2, 0.9) !important;
  margin-bottom: 4vh; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > div > div.button-container.animated.bounceInUp > button:active {
  box-shadow: 0px 0px 0px 2px rgba(253, 106, 2, 0.5) !important;
  background-color: rgba(253, 106, 2, 0.5);
  margin-bottom: 4vh; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > form > input {
  color: white;
  background-color: rgba(253, 106, 2, 0.9);
  border: none !important; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > form > input:hover {
  box-shadow: 0px 0px 0px 2px rgba(253, 106, 2, 0.9) !important; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > form > input:active {
  box-shadow: 0px 0px 0px 2px rgba(253, 106, 2, 0.5) !important;
  background-color: rgba(253, 106, 2, 0.5); }

.form-group {
  margin-top: 5vh;
  margin-bottom: 7vh; }

.id-form-main {
  align-items: center;
  justify-content: center;
  vertical-align: center; }

.id-form-label {
  display: block;
  color: rgba(253, 106, 2, 0.9);
  margin-bottom: 5vh;
  font-size: 30px; }

.id-form-input {
  background-color: gray !important;
  color: #222 !important; }

.form-error {
  display: block;
  color: #fd6a02;
  margin-top: 0;
  margin-bottom: 0; }

.form-disclaimer {
  position: absolute;
  right: 5vh;
  font-size: 14px;
  color: #fd6a02 !important;
  text-decoration: underline !important; }

.form-disclaimer:hover {
  position: absolute;
  right: 5vh;
  font-size: 14px;
  color: #fd6a02 !important;
  text-decoration: underline !important;
  font-weight: 700; }

.grommetux-radio-button__input:checked + .grommetux-radio-button__control:after {
  background-color: #00CCEB !important; }

.grommetux-form-field {
  background-color: transparent !important;
  border-color: #666 !important;
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  margin-top: 5vh;
  margin-bottom: 5vh; }

.survey-form {
  margin-bottom: 2vh;
  margin-top: 2vh; }

.question-form {
  margin-bottom: 3vh !important; }

.grommetux-radio-button__input:checked + .grommetux-radio-button__control {
  border-color: #00CCEB !important; }

.grommetux-radio-button__input:checked + .grommetux-radio-button__control + .grommetux-radio-button__label {
  color: #666 !important; }

.grommetux-radio-button__input {
  display: inline-block !important; }

.survey-adj {
  text-transform: uppercase;
  color: #fd6a02 !important;
  cursor: pointer; }

.grommetux-form-field__contents > .grommetux-check-box, .grommetux-form-field__contents > .grommetux-radio-button {
  display: inline-block !important; }

.grommetux-form-field__contents > .grommetux-check-box, .grommetux-form-field__contents > .grommetux-radio-button {
  margin-left: 70px !important; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > div > button {
  color: white;
  background-color: rgba(253, 106, 2, 0.9);
  border: none !important;
  margin-bottom: 2vh; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > div > button:hover {
  box-shadow: 0px 0px 0px 2px rgba(253, 106, 2, 0.9) !important;
  margin-bottom: 2vh; }

#root > div.grommet.grommetux-app.grommetux-app--centered.App-Main > div:nth-child(1) > div > button:active {
  box-shadow: 0px 0px 0px 2px rgba(253, 106, 2, 0.5) !important;
  background-color: rgba(253, 106, 2, 0.5);
  margin-bottom: 2vh; }

.survey-title {
  color: rgba(253, 106, 2, 0.9);
  text-decoration: underline; }

.grommetux-radio-button:hover:not(.grommetux-radio-button--disabled) .grommetux-radio-button__label {
  color: #666 !important; }

.header-divide {
  border-color: #666 !important;
  height: 1px !important;
  margin-bottom: 3vh; }

.grommetux-form-field__contents > .grommetux-radio-button:active {
  background-color: transparent !important; }

.submit-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  vertical-align: center; }

.submit-message {
  margin-top: 40vh;
  color: rgba(253, 106, 2, 0.9);
  text-decoration: underline; }

.tooltip {
  color: #00CCEB !important;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%; }

.mobile-tip {
  color: #00CCEB !important;
  text-align: center;
  margin-left: 2%;
  margin-right: 2%;
  font-size: 13px; }

.hover-span {
  position: absolute;
  left: 2vh;
  color: #999; }

.mobile-hover-span {
  color: #999; }
