body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #222; }

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
